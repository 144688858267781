var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h1", [_vm._v("Impressum")]),
      _c("h2", [_vm._v("Angaben gemäß § 5 TMG")]),
      _c("p", [
        _vm._v(" SiMiPa GmbH"),
        _c("br"),
        _vm._v(" Wittbräucker Straße 566"),
        _c("br"),
        _vm._v(" 44267 Dortmund ")
      ]),
      _c("h2", [_vm._v("Postanschrift")]),
      _c("p", [
        _vm._v(" SiMiPa GmbH"),
        _c("br"),
        _vm._v(" Postfach 101425"),
        _c("br"),
        _vm._v(" 46214 Bottrop ")
      ]),
      _c("p", [_vm._v("Telefonnummer: +49 (0)2304-960472-0")]),
      _c("p", [_vm._v("E-Mail: info (at) simipa.com")]),
      _c("p", [
        _vm._v(" Geschäftsführer: Andreas Stein "),
        _c("br"),
        _c("br"),
        _vm._v(" Handelsregister Amtsgericht Dortmund "),
        _c("br"),
        _vm._v(" Registernummer: HRB 32308"),
        _c("br"),
        _c("br"),
        _vm._v(" Umsatzsteuer-Identifikationsnummer: DE304741559 ")
      ]),
      _c("strong", [
        _vm._v("Verantwortlich für den Inhalt nach § 18 Abs. 2 MStV:"),
        _c("br"),
        _vm._v("Herr Andreas Stein, Adresse wie oben ")
      ]),
      _c("h2", [_vm._v("Streitbeilegung:")]),
      _c("p", [
        _vm._v(
          " Verbraucher haben die Möglichkeit eine alternative Streitbeilegung zu nutzen. Der folgende Link der EU-Kommission (auch OS-Plattform genannt) enthält Informationen über die Online-Streitschlichtung und dient als zentrale Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten, die aus Online-Kaufverträgen oder Online-Dienstleistungsverträgen erwachsen: "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://ec.europa.eu/consumers/odr",
              target: "_blank",
              rel: "noopener noreferrer"
            }
          },
          [_vm._v(" https://ec.europa.eu/consumers/odr ")]
        ),
        _vm._v(
          " . Informationspflicht gem. Verbraucherstreitbeilegungsgesetz (§ 36 VSBG): Zur Teilnahme an weiteren Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir weder bereit noch verpflichtet. "
        )
      ]),
      _c("h2", [_vm._v("Haftung für Inhalte")]),
      _c("p", [
        _vm._v(
          " Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. "
        )
      ]),
      _c("h3", [_vm._v("Haftung für Inhalte")]),
      _c("p", [
        _vm._v(
          " Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. "
        )
      ]),
      _c("p", [
        _vm._v(
          " Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. "
        )
      ]),
      _c("h3", [_vm._v("Haftung für Links")]),
      _c("p", [
        _vm._v(
          " Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen. "
        )
      ]),
      _c("p", [
        _vm._v(
          " Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen. "
        )
      ]),
      _c("h3", [_vm._v("Urheberrecht")]),
      _c("p", [
        _vm._v(
          " Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. Des Weiteren behalten wir uns das Recht vor, Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen. "
        )
      ]),
      _c("p", [
        _vm._v(
          " Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. "
        )
      ]),
      _c("h3", [_vm._v("Jugendschutzbeauftragter: Kanzlei Bischoff")]),
      _c("p", [
        _vm._v(
          " Adresse: Kanzlei Bischoff, Kerstin Bischoff, Im Alten Dorfe 37, 22359 Hamburg"
        ),
        _c("br"),
        _vm._v(" Tel.: +040 - 60 849 849"),
        _c("br"),
        _vm._v(" Email: Kanzlei-Bischoff@email.de ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }